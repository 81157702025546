import React, { useContext, useEffect, useState } from 'react'
import * as api from './api'
import { Redirect } from 'react-router-dom'
import { LoginContext } from './Contexts'

const Logout = props => {

  const { loggedInUser, setLoggedInUser } = useContext(LoginContext)
  const [error, setError] = useState(false)

  useEffect(() => {
    api.logout().then(ok => {
      setLoggedInUser(null)
    }).catch(err => {
      console.error(err)
      setError(err)
    })
  }, [setLoggedInUser])


  if (loggedInUser) {
    return (
      <Redirect to='/login' />
    )
  }

  return (
    <div>
      {error ? error.message : null}
      <i className="fa fa-refresh fa-spin fa-fw"></i>
    </div>
  )
}

export default Logout
