import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as api from './api'
import Form from './Form'

const editDocFields = [
  ['title', 'text', 'Titlu'],
  ['html', 'static', 'Umpic de text de la inceput'],
  ['publisher_name', 'select', 'Publicat in', ['Monitorul Oficial', 'Instanta de judecata', 'Jurnalul Oficial', 'Monitorul Oficial Partea V-a', 'Lex']],
  ['publish_nr', 'number', 'Numar publicare (nr monitor)'],
  ['publish_year', 'number', 'An publicare'],
  ['publish_date', 'text', 'Data publicarii (data la care a fost publicata - ie. in monitor 2-3 zile dupa)', 'YYYY-MM-DD hh:mm:ss.SSS'],
  ['issue_year', 'number', 'An emitere'],
  ['issue_date', 'text', 'Data emitere (data la care a fost emisa, eg. de parlament)', 'YYYY-MM-DD hh:mm:ss.SSS'],
  ['revdata.rev_date', 'text', 'Data revizie (data in care a fost scrisa in db - ex. la modificare = data la care a fost schimbat', 'YYYY-MM-DD hh:mm:ss.SSS'],
]

const EditActPage = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [chaingingRev, setChangingRev] = useState(false)
  const [docAndChain, setDocAndChain] = useState()
  const [editData, setEditData] = useState({})


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { doc, chain } = await api.getActEditData(id)
        const {
          html,
          title,
          publisher_name,
          revdata: { rev_date },
          issue_date,
          issue_year,
          publish_nr,
          publish_date,
          publish_year,
        } = doc._source

        setDocAndChain({ doc, chain })
        console.log(doc)
        setEditData({ html, title, publisher_name, 'revdata.rev_date': rev_date, publish_nr, issue_date, issue_year, publish_date, publish_year })
      } catch (error) {
        alert(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id])

  const saveData = useCallback(async data => {
    delete data.html
    await api.saveActEditData(id, data)
  }, [id])

  const deleteRev = useCallback(async revid => {
    setChangingRev(true)
    const chain = await api.deleteRev(revid)
    setDocAndChain(({ doc }) => ({ doc, chain }))
    setChangingRev(false)
  }, [])

  const restoreRev = useCallback(async revid => {
    setChangingRev(true)
    const chain = await api.restoreRev(revid)
    setDocAndChain(({ doc }) => ({ doc, chain }))
    setChangingRev(false)
  }, [])

  const fixRevs = useCallback(async revid => {
    setChangingRev(true)
    const chain = await api.fixRevs(revid)
    setDocAndChain(({ doc }) => ({ doc, chain }))
    setChangingRev(false)
  }, [])

  if (!docAndChain) return loading ? <Spinner animation="border" /> : null

  const { doc: { _id, _source: { deleted, revdata, ctime, mtime } }, chain: { _source: { revisions } } } = docAndChain

  return (
    <div className="row my-4">
      <div className="col-8" style={{ backgroundColor: deleted ? 'rgba(255,0,0,0.1)' : "" }}>
        {deleted && <h3>Act marcat ca sters</h3>}
        <Form data={editData} fields={editDocFields} dismissOnSave={false} title={'Date act'} save={saveData} />
        {/* <pre className="small">{JSON.stringify(editData, null, 2)}</pre> */}
      </div >
      <div className="col-4">
        <div className="form-group">
          <div className="form-group small">
            <label>Rev data:</label>
            <pre>{JSON.stringify({revdata, ctime, mtime}, '\t', 1)}</pre>
          </div>
        </div>
        <div className="form-group">
          <div className="form-group small">
            <h3>Revizii:</h3>
            <div className="btn btn-sm btn-dark py-0 px-1" onClick={() => fixRevs(revisions[0].id)}>Resincronizeaza {chaingingRev && <Spinner size="sm" animation="border" />}</div>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th></th>
                  <th>Tip</th>
                  <th>Data</th>
                  <th>Actiuni</th>
                </tr>
              </thead>
              <tbody>
                {revisions.map((rev, index) => (
                  <tr
                    key={rev.id}
                    style={{ border: `2px dashed ${rev.id === _id ? 'rgba(0,0,0,0.2)' : 'transparent'}`, backgroundColor: rev.deleted ? 'rgba(255,0,0,0.1)' : '' }}
                  >
                    <td className="p-1 text-right" style={{ width: 20 }}>{index + 1}.</td>
                    <td className="p-1">{rev.rev_type}</td>
                    <td className="p-1 text-right">{rev.datestr}</td>
                    <td>
                      {chaingingRev
                        ?
                        <Spinner size="sm" animation="border" />
                        : <>
                          {rev.deleted
                            ?
                            <div className="btn btn-sm btn-warning py-0 px-1 btn-restorerev" onClick={() => restoreRev(rev.id)}>Restaureaza</div>
                            :
                            <div className="btn btn-sm btn-danger py-0 px-1 btn-delrev" onClick={() => deleteRev(rev.id)}>Sterge</div>
                          }
                          {rev.id !== _id ? <Link className="btn btn-sm btn-link py-0 px-1" to={`/editact/${rev.id}`}>Editeaza</Link> : null}
                        </>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div >
    </div>
  )
}

export default EditActPage
