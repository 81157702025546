import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as api from './api'
import dayjs from 'dayjs'

import AceEditor from "react-ace";
import 'ace-builds/src-min-noconflict/ext-searchbox';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-twilight";
import { Spinner } from 'react-bootstrap';

const ConcatEditPage = (props) => {

  const [meta, setMeta] = useState()
  const [html, setHtml] = useState()
  const [saving, setSaving] = useState(false)

  const { concatid } = props.match.params

  const fetchMeta = useCallback(async () => {
    const m = await api.getConcatMeta(concatid)
    setMeta(m)
  }, [concatid])

  const fetchHtml = useCallback(async () => {
    const h = await api.getConcatHtml(concatid)
    setHtml(h.html)
  }, [concatid])

  useEffect(() => {
    fetchMeta().then(() => fetchHtml())
  }, [fetchMeta, fetchHtml])

  const aceRef = useRef()

  const gotoNextMarker = () => {
    // console.log(editor.current)
    const editor = aceRef.current.editor
    const range = editor.find('MARKER CONCATENARE', {
      wrap: true,
      caseSensitive: true,
      wholeWord: true,
      regExp: false,
      preventScroll: true // do not change selection
    })
    range.start.column = 0
    range.end.column = Number.MAX_VALUE

    editor.resize(true);
    editor.scrollToLine(range.start.row, true, true, function () { });
    editor.gotoLine(range.start.row, 10, true);
    editor.selection.moveCursorToPosition({ row: range.start.row, column: 0 });
    editor.focus()
  }

  const save = async () => {
    setSaving(true)
    await api.updateConcatHtml(concatid, html)
    setSaving(false)
  }

  return (
    <div className="row mt-4">
      <div className="col-12">
        {meta
          ? (
            <>
              <h3><strong>{meta.newtype} nr. {meta.issuers[0].issuer_nr}/{meta.issue_year}</strong> {meta.publisher_name} nr. {meta.publish_nr}/{meta.publish_year}<br />({meta.revdata.rev_type} din {dayjs(meta.revdata.rev_date).format('DD MMM YYYY')})</h3>
              <h4>{meta.title}</h4>
              <div id='spinner_wrap'></div>
              <i>Pentru a gasi usor locurile de delimitare apasati Ctrl+F si cautati dupa textul <code>MARKER CONCATENARE</code></i>
              <div className="font-italic text-muted small">(<b>Nota:</b> Acest text de delimitare nu se vede in document, e doar pus de concatenarea automata pentru a gasi mai usor locurile unde documentele au fost lipite automat)</div>
              <div className="btn btn-outline-dark" onClick={gotoNextMarker}>Go to marker</div>
            </>
          )
          : <Spinner animation="border" />}
        {html
          ? <AceEditor
            ref={aceRef}
            name="concateditor"
            style={{ width: '100%' }}
            mode="html"
            theme="twilight"
            debounceChangePeriod={500}
            wrapEnabled
            className="p-3 my-2"
            readOnly={false}
            onChange={setHtml}
            setOptions={{
              useWorker: false
            }}
            value={html} />
          : <Spinner animation="border" />}
        <div className="btn btn-info" onClick={save}>Save {saving && <Spinner animation="border" size="sm" />}</div>
      </div>
    </div>
  )
}

export default ConcatEditPage
