import { useEffect, createContext, useState, useContext, useCallback } from 'react'
import * as api from './api'
import * as Icons from 'react-bootstrap-icons'

export const LoginContext = createContext({ loggedInUser: null, setLoggedInUser: () => { } })

export const LoginContextWrapper = (props) => {
  const [loggedInUser, setLoggedInUser] = useState()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    // console.log('LoginWrapper check auth', loggedInUser)
    const check = async () => {
      try {
        // console.log('checking Auth')
        const user = await api.checkAuth()
        // console.log('check result', user)
        setLoggedInUser(user)
        setChecked(true)
      } catch (err) {
        console.error('check failed', err)
        setChecked(true)
      }
    }
    if (!checked) check()
  }, [checked])

  if (!checked) return 'Checking...'
  return (
    <LoginContext.Provider value={{ loggedInUser, setLoggedInUser }}>
      {props.children}
    </LoginContext.Provider>
  )
}

export const DataContext = createContext({
  loading: false,
  loaded: false,
  companies: [],
  resellers: [],
  reloadImports: () => { },
  addCompany: () => { },
  updateCompany: () => { },
  removeCompany: () => { },
  updateReseller: () => { },
  updateMulti: () => { },
  addReseller: () => { },
  removeReseller: () => { },
})

export const DataContextWrapper = (props) => {

  const { loggedInUser } = useContext(LoginContext)
  const [data, setData] = useState({ loaded: false, companies: [], resellers: [] })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()


  const fetchImports = useCallback(async () => {
    // console.log('fetching data')
    if (loggedInUser.level === 0) {
      try {
        setLoading(true)
        setError(null)
        const { uploaded, imported } = await api.getImports()
        setData(({ companies, resellers }) => ({ loaded: true, companies, resellers, uploaded, imported }))
        setLoading(false)
      } catch (e) {
        console.error(e)
        setError('Eroare la incarcarea datelor')
        setLoading(false)
      }
    }
  }, [loggedInUser])

  const fetchData = useCallback(async () => {
    // console.log('fetching data')
    try {
      setLoading(true)
      setError(null)
      const companies = await api.getCompanies()
      if (loggedInUser.level === 0) {
        const { uploaded, imported } = await api.getImports()
        const resellers = await api.getResellers()
        setData({ loaded: true, companies, resellers, uploaded, imported })
      } else {
        setData({ loaded: true, companies, resellers: [], uploaded: [], imported: [] })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setError('Eroare la incarcarea datelor')
      setLoading(false)
    }
  }, [loggedInUser])

  const updateSingleCompany = useCallback(async company => {
    setData(prev => ({
      ...prev,
      companies: prev.companies.map(c => c._id === company._id ? company : c),
    }))
  }, [])

  const updateSingleReseller = useCallback(async reseller => {
    setData(prev => ({
      ...prev,
      resellers: prev.resellers.map(r => r._id === reseller._id ? reseller : r),
    }))
  }, [])

  const updateMultiple = useCallback(async ({ companies: updatedCompanies, resellers: updatedResellers }) => {
    setData(prev => ({
      ...prev,
      companies: prev.companies.map(c => updatedCompanies.find(uc => uc._id === c._id) || c),
      resellers: prev.resellers.map(r => updatedResellers.find(ur => ur.id === r._id) || r),
    }))
  }, [])

  const removeSingleCompany = useCallback(async companyId => {
    setData(prev => ({
      ...prev,
      companies: prev.companies.filter(c => c._id !== companyId),
    }))
  }, [])

  const addNewCompany = useCallback(async company => {
    setData(prev => ({
      ...prev,
      companies: [company, ...prev.companies],
    }))
  }, [])

  const addNewReseller = useCallback(async reseller => {
    setData(prev => ({
      ...prev,
      resellers: [reseller, ...prev.resellers],
    }))
  }, [])

  const removeSingleReseller = useCallback(async resellerId => {
    setData(prev => ({
      ...prev,
      resellers: prev.resellers.filter(r => r._id !== resellerId),
    }))
  }, [])

  // console.log('loggedInUser', loggedInUser)
  useEffect(() => {
    if (loggedInUser) fetchData()
  }, [loggedInUser, fetchData])

  if (loggedInUser && !data.loaded) return 'Se incarca...'
  if (loggedInUser && error) return <div>
    <h2>{error}</h2>
    <div className="btn btn-lg btn-primary"><Icons.BootstrapReboot /> Reincarcare</div>
  </div>
  return <DataContext.Provider value={{
    ...data, loading,
    reloadImports: fetchImports,
    addCompany: addNewCompany,
    updateCompany: updateSingleCompany,
    removeCompany: removeSingleCompany,
    updateMulti: updateMultiple,
    updateReseller: updateSingleReseller,
    addReseller: addNewReseller,
    removeReseller: removeSingleReseller,
  }}>
    {props.children}
  </DataContext.Provider>
}
