import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import _ from 'lodash'
import * as Icons from 'react-bootstrap-icons'
import { DataContext } from './Contexts'
import * as api from './api'
import Form from './Form'
import CompanyUsersTable from './CompanyUsersTable'
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const addUserFormFields = [
  ['name', 'text', 'Nume'],
  ['email', 'email', 'Email'],
  ['pwd', 'password', 'Parola'],
  ['pwdconfirm', 'password', 'Confirmarea parolei'],
]

const updateCompanyDataFormFields = [
  ['name', 'text', 'Nume'],
  ['description', 'textarea', 'Descriere'],
  ['expdate', 'date', 'Data expirarii'],
  ['canLogin', 'switch', 'Acces Mono'],
  ['canUpdate', 'switch', 'Update Mono']
]

const Lic = ({ index, license, installer, onDelete, onChangeActivationStatus }) => {
  const [sysinfoVisible, setSysinfoVisible] = useState(false)
  const activeSwitch = useMemo(() => license && <div className="fs-5">
    <div className="ms-2 form-check-inline form-switch fs-5">
      <input className="form-check-input" type="checkbox"
        checked={license.active}
        onChange={e => onChangeActivationStatus?.(e.target.checked)}
      />
    </div>
    {license.active ? 'Activa' : 'Inactiva'}
  </div>, [license, onChangeActivationStatus])
  return (
    <div className={`col-${license ? '12' : '4'} mb-3`}>
      <div className={`p-2 text-light rounded ${license ? (license.active ? 'bg-dark' : 'bg-danger') : 'bg-secondary'}`}>
        <div className="d-flex justify-content-between align-items-start">
          <div className="fs-4">Licenta {index + 1}</div>
          {activeSwitch}
          <button className="btn btn-outline-light" onClick={onDelete}><Icons.TrashFill /></button>
        </div>
        {!license
          ? <h5>Libera</h5>
          : (
            <div>
              <div>Emisa: {dayjs(license.iat).format('DD/MM YYYY')} Expira: {dayjs(license.exp).format('DD/MM YYYY')}</div>
              <div>Instalata de: {installer.name}/{installer.email}</div>
              <div className="btn btn-sm btn-outline-info" onClick={() => setSysinfoVisible(prev => !prev)}>Informatii system</div>
              {sysinfoVisible && <pre className="bg-light text-dark mt-2">{JSON.stringify(license.systemInfo, null, 2)}</pre>}
            </div>
          )
        }
      </div>
    </div>
  )
}

const Licenses = ({ company, onAdd, onDelete, onToggleActive }) => {
  const { licenses, max_licenses } = company

  return <div className="row bg-light d-flex flex-wrap align-items-stretch">
    {_.range(0, max_licenses).map(i => (
      <Lic
        key={`lic-${licenses[i] ? licenses[i]._id : i}`}
        license={licenses[i]}
        installer={licenses[i] && company.users.find(u => u._id === licenses[i].installer)}
        index={i}
        onDelete={() => onDelete(licenses[i])}
        onChangeActivationStatus={() => onToggleActive(licenses[i])}
      />
    ))}
  </div>
}


export const Company = ({ companyId }) => {
  const { companies, updateCompany, resellers, updateMulti } = useContext(DataContext)

  const company = useMemo(() => companies.find(c => c._id === companyId), [companies, companyId])
  console.log({ companyId, company })
  console.log('Company.users', company.users)
  const [newUserFormVisible, setNewUserFormVisible] = useState(false)

  const addUser = async newUserData => {
    try {
      const data = await api.addCompanyUser(company._id, {...newUserData, expdate: company.expdate})
      console.log('Add result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        updateCompany(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const addLicense = async () => {
    try {
      updateCompany(await api.incLicenses(companyId))
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const deleteLicense = async lic => {
    try {
      if (lic) {
        updateCompany(await api.deleteLicense(companyId, lic._id))
      } else {
        updateCompany(await api.decLicenses(companyId))
      }

    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const toggleLicenseActive = async lic => {
    if (!lic) return
    try {
      updateCompany(await api.setLicenseActive(companyId, lic._id, !lic.active))
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const transferCompany = async newResellerId => {
    try {
      const [updatedCompany, updatedOldReseller, updatedNewReseller] = await api.transferCompany(company.id, newResellerId)
      updateMulti({
        companies: [updatedCompany],
        resellers: [updatedOldReseller, updatedNewReseller],
      })
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const updateCompanyData = async updatedCompanyData => {
    try {
      const { expdate } = updatedCompanyData
      const data = await api.updateCompanyData(company._id, { ...updatedCompanyData, expdate: dayjs(expdate, 'DD-MM-YYYY').format('YYYY-MM-DD') })
      console.log('Add result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        updateCompany(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const { days, ago, expdatestr } = company

  const [companyEditFormVisible, setCompanyEditFormVisible] = useState(false)

  const filteredResellers = useMemo(() => resellers.filter(r => r.id !== company.reseller.id).sort((a, b) => a.name < b.name ? -1 : 1)
    , [resellers, company.reseller.id])

  const perms = useMemo(() => {
    if (company && company.caps && company.caps.offline) return {
      canLogin: company.caps.offline.canLogin,
      canUpdate: company.caps.offline.canUpdate
    }
    return {
      canLogin: false,
      canUpdate: false,
    }
  }, [company])

  if (!company) return null
  return (
    <div>
      <div className="container-fluid py-3 mt-2 bg-light">
        {!companyEditFormVisible && <>
          <div className="fs-4 fw-bold d-flex justify-content-between align-items-start">
            {company.name}
            <div className="btn btn-sm btn-outline-dark" onClick={() => setCompanyEditFormVisible(true)}><Icons.PencilSquare /> Edit</div>
          </div>
          <div className="fs-5">{company.description}</div>
          <div className="fs-5 d-flex align-items-center mt-1">
            <div className={days < 0 ? 'bg-danger text-white p-2 rounded' : days < 7 ? 'p-2 rounded bg-warning' : ''}>
              <div>{days < 0 ? 'Expirat ' : 'Expira '}{ago}
                &nbsp;
                ({days} {Math.abs(days) === 1 ? 'zi' : 'zile'})
                &nbsp; {expdatestr}
              </div>
            </div>
          </div>
          <div className='fs-5'>
            Acces Mono: {perms.canLogin ? "DA" : "NU"}
          </div>
          <div className='fs-5'>
            Update Mono: {perms.canUpdate ? "DA" : "NU"}
          </div>
        </>}
        {companyEditFormVisible && <Form
          title="Editare date companie"
          data={{ name: company.name, description: company.description, expdate: dayjs(company.expdate).format('DD-MM-YYYY'), canLogin: perms.canLogin, canUpdate: perms.canUpdate }}
          fields={updateCompanyDataFormFields}
          dismiss={() => setCompanyEditFormVisible(false)}
          save={updateCompanyData}
        />}
        <div className="fs-5 d-flex align-items-center mt-1">
          Reseller: {company.reseller.name}
          <div className="dropdown ms-3">
            <div className="btn btn-sm btn-outline-dark dropdown-toggle" type="button"
              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <Icons.ArrowLeftRight /> Transfer
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {filteredResellers.map(({ id, name }, i) => (
                <li key={`res-${id}`}><div className="dropdown-item" onClick={e => {
                  const goon = window.confirm(`Sigur vrei sa muti compania la ${name}?`)
                  if (goon) transferCompany(id)
                }}>{name}</div></li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="py-2">
            <div className="d-flex align-items-center mb-2">
              <div className="fs-5">Utilizatori</div>
              <button className="btn btn-sm btn-outline-dark ms-3" onClick={() => setNewUserFormVisible(prev => !prev)}><Icons.PlusCircleFill /> Adauga</button>
            </div>
            {newUserFormVisible && <Form
              data={{ name: '', email: '', pwd: '', pwdconfirm: '' }}
              fields={addUserFormFields}
              dismiss={() => setNewUserFormVisible(false)}
              save={addUser}
            />}
            <CompanyUsersTable company={company} />
          </div>
        </div>
        <div className="col-6">
          <div className="py-2">
            <div className="d-flex align-items-center mb-2">
              <div className="fs-5">Licente</div>
              <button className="btn btn-sm btn-outline-dark ms-3" onClick={addLicense}><Icons.PlusCircleFill /> Adauga</button>
            </div>
            <Licenses
              company={company}
              onAdd={addLicense}
              onDelete={deleteLicense}
              onToggleActive={toggleLicenseActive} />
          </div>
        </div>
      </div>
    </div>
  )
}


export const CompanyPage = () => {
  const params = useParams()
  const { id: companyId } = params
  console.log('companyId', companyId)
  return <Company companyId={companyId} />
}

