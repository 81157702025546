import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { DataContext, LoginContext } from './Contexts'
import * as api from './api'
import * as Icons from 'react-bootstrap-icons'

import { Table } from './Table'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
import Form from './Form'
import BForm from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


const addCompanyFormFields = [
  ['name', 'text', 'Nume'],
  ['description', 'textarea', 'Descriere'],
  ['expdate', 'date', 'Expira in'],
  ['canLogin', 'switch', 'Acces Mono'],
  ['canUpdate', 'switch', 'Update Mono'],
]

const updateCompanyDataFormFields = [
  ['name', 'text', 'Nume'],
  ['description', 'textarea', 'Descriere'],
  ['expdate', 'date', 'Data expirarii'],
  ['canLogin', 'switch', 'Acces Mono'],
  ['canUpdate', 'switch', 'Update Mono']
]

const applyExpirationFilter = (data, filter) => {
  switch (filter) {
    case 'Expirate':
      return data.filter(r => r.days < 0);
    case 'Ne-expirate':
      return data.filter(r => r.days >= 0);
    case 'Expira in 0-7 zile':
      return data.filter(r => r.days >= 0 && r.days < 8);
    case 'Expira in 7-14 zile':
      return data.filter(r => r.days >= 7 && r.days < 15);
    case 'Expira in 14-30 zile':
      return data.filter(r => r.days >= 14 && r.days < 31);
    case 'Expira in 30+ zile':
      return data.filter(r => r.days >= 30);
    case 'Toate':
    default:
      return data;
  }
}

const applySearchFilter = (data, filter) => {
  if (!filter) return data
  return data.filter(r =>
    (r.name && r.name.toLowerCase().includes(filter.toLowerCase())) ||
    (r.users && r.users.find(u => u.name && u.name.toLowerCase().includes(filter.toLowerCase()))) ||
    (r.users && r.users.find(u => u.email && u.email.toLowerCase().includes(filter.toLowerCase())))
  );
}

const applyResellerFilter = (data, resellerId) => {
  if (!resellerId) return data
  return data.filter(r => r.reseller && r.reseller.id && r.reseller.id === resellerId);
}

const applyFilters = (data, searchFilter, expireFilter, reseller) => {
  let filteredData = data.slice(0);

  if (expireFilter !== 'Toate') filteredData = applyExpirationFilter(filteredData, expireFilter);
  if (searchFilter) filteredData = applySearchFilter(filteredData, searchFilter);
  if (reseller && reseller.name && reseller.name !== 'Toti') filteredData = applyResellerFilter(filteredData, reseller.id);

  return filteredData;
}

const CompaniesPage = () => {

  const { filter } = useParams();

  const properNumberSorter = useCallback((a, b, id) => {
    // console.log('ROWS',a,b)
    return a.values[id] < b.values[id] ? -1 : 1
  }, [])

  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'nrcrt',
        Cell: ({ row: { index } }) => {
          return (
            <div>
              {index + 1}
            </div>
          )
        }
      },
      {
        Header: 'Nume',
        accessor: 'name',
      },
      {
        Header: 'Expirare (zile)',
        accessor: 'days',
        sortDescFirst: true,
        Cell: ({ row: { original: { expdate, days, ago, expdatestr } } }) =>
          <div className={days < 0 ? 'bg-danger text-white' : days < 7 ? 'bg-warning' : ''}>
            <div>{days < 0 ? 'Expirat ' : 'Expira '}{ago}
              &nbsp;
              ({days} {Math.abs(days) === 1 ? 'zi' : 'zile'})
            </div>
            <i>
              {expdatestr}
            </i>
          </div>,
        sortType: properNumberSorter
      },
      {
        Header: 'Utilizatori',
        accessor: ({ users }) => users?.length || 0,
        sortDescFirst: true,
        isNumber: true,
      },
      {
        Header: 'Sesiuni active',
        accessor: ({ users }) => {
          let allActiveTokens = 0;
          users.forEach(u => allActiveTokens += Number(u.activeTokens?.[0] || 0))
          return allActiveTokens;
        },
        sortDescFirst: true,
        isNumber: true,
      },
      {
        Header: 'Licente Disponibile',
        accessor: 'max_licenses',
        sortDescFirst: true,
        isNumber: true,
      },
      {
        Header: 'Licente Instalate',
        accessor: 'licenses.length',
        sortDescFirst: true,
        isNumber: true,
      },
      {
        Header: 'Acces Mono',
        accessor: ({ caps }) => caps && caps.offline && caps.offline.canLogin ? 'DA' : 'NU'

      },
      {
        Header: 'Update Mono',
        accessor: ({ caps }) => caps && caps.offline && caps.offline.canUpdate ? 'DA' : 'NU'
      },
      {
        Header: 'Reseller',
        accessor: 'reseller.name',
      },
      {
        Header: '',
        id: 'actions',
        Cell: ({ row: { original }, actions }) => (
          <div className='d-flex flex-row' style={{ gap: '0.5em' }}>
            <div className="btn btn-sm btn-outline-danger" onClick={e => {
              e.stopPropagation()
              actions.deleteCompany(original)
            }}>
              <Icons.DashCircleFill /> Sterge
            </div>
            <div className="btn btn-sm btn-outline-primary" onClick={e => {
              e.stopPropagation()
              history.push(`/client/${original._id}`)
            }}>
              <Icons.PencilFill /> Detalii
            </div>
          </div>
        )
      },
    ],
    [history, properNumberSorter]
  )
  const { companies, removeCompany, addCompany, updateCompany, updateMulti, resellers } = useContext(DataContext)

  const { loggedInUser } = useContext(LoginContext)
  const [newCompanyFormVisible, setNewCompanyFormVisible] = useState(false)

  const expireFilters = [
    'Toate',
    'Expirate',
    'Ne-expirate',
    'Expira in 0-7 zile',
    'Expira in 7-14 zile',
    'Expira in 14-30 zile',
    'Expira in 30+ zile'
  ]

  const resellerFilters = useMemo(() => {
    const f = [{ name: 'Toti', id: -1 }];
    f.push(...resellers.map(({ name, id }) => ({ name, id })));
    return f;
  }, [resellers])

  const [searchFilter, setSearchFilter] = useState('');
  const [expireFilter, setExpireFilter] = useState('Toate');
  const [resellerFilter, setResellerFilter] = useState({ name: 'Toti', id: -1 });

  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  useEffect(() => {
    switch (filter) {
      case 'expired':
        setExpireFilter('Expirate');
        break;
      case 'close-to-expiration':
        setExpireFilter('Expira in 0-7 zile');
        break;
      default:
        setExpireFilter('Toate');
    }

    setSearchFilter('');
    setResellerFilter('Toti');
  }, [filter])

  useEffect(() => {
    const updatedCompanies = applyFilters(companies, searchFilter, expireFilter, resellerFilter);
    setFilteredCompanies(updatedCompanies);
  }, [expireFilter, searchFilter, companies, resellerFilter])

  const addCompanyFormFieldsAdmin = useMemo(() => [
    ['reseller', 'select', 'Reseller', resellers.map(r => ({ value: r.id, label: r.name }))],
    ['name', 'text', 'Nume'],
    ['description', 'textarea', 'Descriere'],
    ['expdate', 'date', 'Expira in'],
    ['canLogin', 'switch', 'Acces Mono'],
    ['canUpdate', 'switch', 'Update Mono'],
  ], [resellers])

  const perms = useCallback((company) => {
    if (company && company.caps && company.caps.offline) return {
      canLogin: company.caps.offline.canLogin,
      canUpdate: company.caps.offline.canUpdate
    }
    return {
      canLogin: false,
      canUpdate: false,
    }
  }, [])

  const addNewCompany = async newCompanyData => {
    try {
      const { expdate } = newCompanyData
      newCompanyData.expdate = dayjs(expdate, 'DD-MM-YYYY').toDate().toISOString()
      const data = await api.addCompany(newCompanyData)
      console.log('Add result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        addCompany(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const transferCompany = async (newResellerId, companyId) => {
    try {
      const [updatedCompany, updatedOldReseller, updatedNewReseller] = await api.transferCompany(companyId, newResellerId)
      updateMulti({
        companies: [updatedCompany],
        resellers: [updatedOldReseller, updatedNewReseller],
      })
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const getReseller = useCallback((resellerName) => resellerFilters.find(r => r.name === resellerName), [resellerFilters])

  const updateCompanyData = async (updatedCompanyData, companyId) => {
    try {
      const { expdate } = updatedCompanyData
      const data = await api.updateCompanyData(companyId, { ...updatedCompanyData, expdate: dayjs(expdate, 'DD-MM-YYYY').format('YYYY-MM-DD') })
      console.log('Add result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        updateCompany(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }


  return (
    <div className="mt-2">
      <div className="mb-2 d-flex align-items-center justify-content-start">
        <div className="fs-2">Clienti</div>
        <span className="badge bg-dark px-2 py-1 fs-3 ms-3">{filteredCompanies.length} {filteredCompanies.length !== companies.length ? ` (din ${companies.length})` : ''}</span>
        <button className="btn btn-outline-primary ms-3 d-flex flex-row align-items-center" style={{ gap: '0.5em' }} onClick={() => setNewCompanyFormVisible(true)}><Icons.PlusCircleFill /> Adauga</button>
      </div>
      {newCompanyFormVisible && <Form
        title='Client nou'
        data={{
          name: '',
          description: '',
          expdate: dayjs().add(1, 'y').format('DD-MM-YYYY'),
          reseller: loggedInUser.level === 0 ? resellers.find(r => r.email === 'lavinia_tatu@yahoo.com')?.id || '' : '',
          canLogin: true,
          canUpdate: true,
        }}
        fields={loggedInUser.level === 0 ? addCompanyFormFieldsAdmin : addCompanyFormFields}
        dismiss={() => setNewCompanyFormVisible(false)}
        save={addNewCompany}
      />}
      <div className='batman-filter-panel'>

        {
          // search filter
        }
        <FloatingLabel
          controlId="floatingInput"
          label="Cautare"
          className="w-100"
        >
          <BForm.Control
            type="text"
            placeholder="Introdu textul de cautare"
            className='batman-filter-search'
            variant='outline-secondary'
            onChange={(e) => setSearchFilter(e.target.value)}
            value={searchFilter}
          />
        </FloatingLabel>

        {
          // Expiration filter
        }
        <FloatingLabel
          controlId="floatingSelect"
          label="Expira"
        >
          <BForm.Select
            value={`${expireFilter} (${applyFilters(companies, searchFilter, expireFilter, resellerFilter).length})`}
            onChange={(e) => setExpireFilter(e.target.value.split('(')[0].trim())}
            className='batman-filter-text'
          >
            {
              expireFilters.map((f, idx) =>
                <option key={idx} >
                  {f} ({applyFilters(companies, searchFilter, f, resellerFilter).length})
                </option>
              )
            }
          </BForm.Select>
        </FloatingLabel>

        {
          // Reseller filter
        }

        <FloatingLabel
          controlId="floatingSelect"
          label="Reseller"
        >
          <BForm.Select
            value={`${resellerFilter.name} (${applyFilters(companies, searchFilter, expireFilter, resellerFilter).length})`}
            onChange={(e) => setResellerFilter(getReseller(e.target.value.split('(')[0].trim()))}
            className='batman-filter-text'
          >
            {
              resellerFilters.map((r, idx) =>
                <option key={idx} >
                  {r.name} ({applyFilters(companies, searchFilter, expireFilter, r).length})
                </option>
              )
            }
          </BForm.Select>
        </FloatingLabel>


        {
          // Clear filters
        }
        <div
          className='btn btn-info batman-filter-button'
          onClick={() => {
            setExpireFilter('Toate');
            setSearchFilter('');
            setResellerFilter({ name: 'Toti', id: -1 })
          }}
        >
          Anuleaza filtrele
        </div>
      </div>
      <Table
        data={filteredCompanies}
        columns={columns}
        showGlobalFilter={false}
        onRowClick={(row, toggleAllRowsExpanded) => {
          console.log('Row click', row)
          if (!row.isExpanded) {
            toggleAllRowsExpanded(false)
          }
          row.toggleRowExpanded()
        }}
        renderRowExpansion={({ original: company }, toggleAllRowsExpanded) =>
          <>
            <Form
              title="Editare date companie"
              data={{
                name: company.name,
                description: company.description,
                expdate: dayjs(company.expdate).format('DD-MM-YYYY'),
                canLogin: perms(company).canLogin,
                canUpdate: perms(company).canUpdate
              }}
              fields={updateCompanyDataFormFields}
              dismiss={() => toggleAllRowsExpanded(false)}
              save={(data) => updateCompanyData(data, company._id)}
              dismissOnSave={false}
            />
            {/* <div className="fs-5 d-flex align-items-center mt-1 px-4">
              Reseller: {company.reseller.name}
              <div className="dropdown ms-3">
                <div className="btn btn-sm btn-outline-dark dropdown-toggle" type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <Icons.ArrowLeftRight /> Transfer
                </div>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {
                    resellers
                      .filter(r => r.id !== company.reseller.id).sort((a, b) => a.name < b.name ? -1 : 1)
                      .map(({ id, name }, i) => (
                        <li key={`res-${id}`}><div className="dropdown-item" onClick={e => {
                          const goon = window.confirm(`Sigur vrei sa muti compania la ${name}?`)
                          if (goon) transferCompany(id, company.id)
                        }}>{name}</div></li>
                      ))
                  }
                </ul>
              </div>
            </div> */}
          </>
        }
        actions={{
          deleteCompany: async company => {
            if (company.users.length) return alert('Nu se poate sterge o companie atata timp cat are utilizatori')
            const goon = window.confirm(`Sigur doriti sa stergeti compania ${company.name}?`)
            if (goon) {
              await api.deleteCompany(company.id)
              removeCompany(company.id)
            }
          }
        }}
      />
    </div>
  )
}

export default CompaniesPage
