import React, { useEffect } from 'react'
import * as Icons from 'react-bootstrap-icons'
import { matchSorter } from 'match-sorter'
import { useTable, useFilters, useExpanded, usePagination, useSortBy, useAsyncDebounce, useGlobalFilter } from 'react-table'

const Pagination = ({ canPreviousPage, canNextPage, gotoPage, previousPage, nextPage, pageIndex, pageOptions, pageCount, pageSize, setPageSize }) => (
  <div className="d-flex justify-content-between align-items-center">
    <nav aria-label="Navigare">
      <ul className="pagination justify-content-center mb-0">
        <li className={`page-item ${!canPreviousPage && 'disabled'}`}>
          <div className="page-link" tabIndex="-1" onClick={() => gotoPage(0)}><Icons.ChevronBarLeft /></div>
        </li>
        <li className={`page-item ${!canPreviousPage && 'disabled'}`}>
          <div className="page-link" tabIndex="-1" onClick={() => previousPage()}><Icons.ChevronLeft /></div>
        </li>
        <li className="page-item"><div className="page-link">{pageIndex + 1} / {pageOptions.length}</div></li>
        <li className={`page-item ${!canNextPage && 'disabled'}`}>
          <div className="page-link" tabIndex="-1" onClick={() => nextPage()}><Icons.ChevronRight /></div>
        </li>
        <li className={`page-item ${!canNextPage && 'disabled'}`}>
          <div className="page-link" tabIndex="-1" onClick={() => gotoPage(pageCount - 1)}><Icons.ChevronBarRight /></div>
        </li>
      </ul>
    </nav>
    <span>
      Salt la pagina:{' '}
      <input
        type="number"
        defaultValue={pageIndex + 1}
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
        style={{ width: '100px' }}
      />
    </span>
    <select
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value))
      }}
    >
      {[10, 20, 30, 40, 50].map(size => (
        <option key={size} value={size}>
          Arata cate {size} per pagina
        </option>
      ))}
    </select>
  </div>
)

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(filter => {
    setGlobalFilter(filter || undefined)
  }, 200)

  const label = React.useMemo(() => `Cautare`, [count])
  return (
    <div className="form-floating">
      <input type="text" className="form-control" style={{ backgroundColor: 'rgba(255,255,0, 0.1)' }} placeholder={label}
        value={value || ""} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
      <label htmlFor="floatingInput">{label}</label>
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = val => !val

export const Table = ({
  columns,
  data,
  actions,
  onRowClick,
  renderRowExpansion,
  showGlobalFilter = true,
  skipPageReset = false,
  outsideFilter,
},
) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: () => null,
      disableGroupBy: true,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleAllRowsExpanded,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: !skipPageReset,
      actions,
      defaultCanFilter: false,
      outsideFilter,
    },
    useGlobalFilter,
    useFilters,
    // useGroupBy,
    useSortBy,
    useExpanded, // useGroupBy would be pretty useless without useExpanded ;)
    usePagination,
  )

  useEffect(() => {
    if (outsideFilter)
      outsideFilter.forEach(f => {
        setFilter(f.column, f.value)
      })
  }, [outsideFilter])

  return (
    <>
      {/* <pre>
        <code>{JSON.stringify({ groupBy, expanded }, null, 2)}</code>
      </pre> */}
      <table {...getTableProps()} className="table table-hover table-sm table-bordered align-middle">
        <thead className="table-light">
          {showGlobalFilter && preGlobalFilteredRows.length > pageSize && (
            <tr className="p-1 border-0 bg-transparent">
              <th
                colSpan={visibleColumns.length}
                className="p-1 border-0 bg-transparent"
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          )}

          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}  style={{ backgroundColor: column.Header === 'Sesiuni active' ?'#CEC4F6' : '#F8F9FA'}}>
                    <div className="d-flex justify-content-between align-items-center">
                      {column.render('Header')}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      <div className="fs-4">
                        {column.canSort ? column.isSorted
                          ? column.isSortedDesc
                            ? (column.isNumber ? <Icons.SortNumericDownAlt /> : <Icons.SortAlphaDownAlt />)
                            : (column.isNumber ? <Icons.SortNumericUp /> : <Icons.SortAlphaUp />)
                          : null
                          : null}
                      </div>
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...row.getRowProps()} onClick={() => onRowClick?.(row, toggleAllRowsExpanded)}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        // For educational purposes, let's color the
                        // cell depending on what type it is given
                        // from the useGroupBy hook
                        {...cell.getCellProps()}
                        style={{
                          background: cell.column.Header === 'Sesiuni active' ? '#CEC4F6' : 'white'
                        }}
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? '👇' : '👉'}
                            </span>{' '}
                            {cell.render('Cell')} ({row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowExpansion?.(row, toggleAllRowsExpanded)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      <br />
      {rows.length > pageSize && <Pagination {...{ canPreviousPage, canNextPage, gotoPage, previousPage, nextPage, pageIndex, pageOptions, pageCount, pageSize, setPageSize }} />}
    </>
  )
}
