import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DataContext } from './Contexts'
import * as api from './api'

import { Table } from './Table'
import Form from './Form'
import BForm from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import moment from 'moment'
import * as Icons from 'react-bootstrap-icons'
import { useParams } from 'react-router'

const addResellerFormFields = [
  ['name', 'text', 'Nume'],
  ['email', 'email', 'Email'],
  ['expdate', 'date', 'Data expirarii'],
  ['pwd', 'password', 'Parola'],
  ['pwdconfirm', 'password', 'Confirmarea parolei'],
]

const updateResellerDataFormFields = [
  ['name', 'text', 'Nume'],
  ['email', 'email', 'Email'],
  ['expdate', 'date', 'Data expirarii'],
  ['', 'subtitle', 'Schimbare parola (optional)'],
  ['pwd', 'password', 'Parola'],
  ['pwdconfirm', 'password', 'Confirmarea parolei'],
]

const applyFilters = (data, searchFilter, activeFilter, expireFilter) => {
  let filteredData = data.slice(0);

  if (expireFilter !== 'Toate') filteredData = applyExpirationFilter(filteredData, expireFilter);
  if (activeFilter !== 'Toate') filteredData = applyActiveFilter(filteredData, activeFilter);
  if (searchFilter) filteredData = applySearchFilter(filteredData, searchFilter);

  return filteredData;
}

const applyExpirationFilter = (data, filter) => {
  switch (filter) {
    case 'Expirate':
      return data.filter(r => r.days < 0);
    case 'Ne-expirate':
      return data.filter(r => r.days >= 0);
    case 'Expira in 0-7 zile':
      return data.filter(r => r.days >= 0 && r.days < 8);
    case 'Expira in 7-14 zile':
      return data.filter(r => r.days >= 7 && r.days < 15);
    case 'Expira in 14-30 zile':
      return data.filter(r => r.days >= 14 && r.days < 31);
    case 'Expira in 30+ zile':
      return data.filter(r => r.days >= 30);
    case 'Toate':
    default:
      return data;
  }
}
const applyActiveFilter = (data, filter) => {
  switch (filter) {
    case 'Activ':
      return data.filter(r => !r.suspended);
    case 'Inactiv':
      return data.filter(r => r.suspended);
    case 'Toate':
    default:
      return data;
  }
}
const applySearchFilter = (data, filter) => {
  if (!filter) return data
  return data.filter(r => (r.name && r.name.toLowerCase().includes(filter.toLowerCase())) || (r.email && r.email.toLowerCase().includes(filter.toLowerCase())));
}

const Resellers = () => {
  const properNumberSorter = useCallback((a, b, id) => {
    // console.log('ROWS',a,b)
    return a.values[id] < b.values[id] ? -1 : 1
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Nume',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Expirare (zile)',
        accessor: 'days',
        sortDescFirst: true,
        // isNumber: true,
        disableGroupBy: true,
        aggregate: 'sum',
        // Aggregated: ({ value }) => `${value} Expirati`,
        Cell: ({ row: { original: { expdate, days, ago, expdatestr } } }) => <div className={days < 0 ? 'bg-danger text-white' : days < 7 ? 'bg-warning' : ''}>
          <div>{days < 0 ? 'Expirat ' : 'Expira '}{ago}
            &nbsp;
            ({days} {Math.abs(days) === 1 ? 'zi' : 'zile'})
          </div>
          <i>
            {expdatestr}
          </i>
        </div>,
        sortType: properNumberSorter
      },
      {
        Header: 'Companii',
        accessor: 'companies',
        sortDescFirst: true,
        isNumber: true,
      },
      {
        Header: 'Activ',
        accessor: 'suspended',
        Cell: ({
          row: { original: { _id, suspended } },
          actions,
        }) =>
          <div className="form-check form-switch fs-5">
            {suspended ? 'NU' : 'DA'}
            <input className="form-check-input" type="checkbox"
              onClick={e => {
                // e.preventDefault()
                e.stopPropagation()
              }}
              checked={!suspended}
              onChange={e => {
                console.log('click', _id, e.target.checked)
                actions.setSuspended({ _id, suspended: !e.target.checked })
              }}
            />
          </div>,
        sortType: properNumberSorter
      },
      {
        Header: '',
        id: 'actions',
        Cell: (instance) => {
          const {
            row: { original },
            actions,
          } = instance
          return <div className="form-check form-switch fs-5">
            <div className="btn btn-sm btn-outline-danger" onClick={e => {
              e.stopPropagation()
              actions.deleteReseller(original)
            }}><Icons.DashCircleFill /> Sterge</div>
          </div>
        }
      },
    ],
    [properNumberSorter]
  )
  const { resellers, addReseller, updateReseller, removeReseller } = useContext(DataContext)
  const [newUserFormVisible, setNewUserFormVisible] = useState(false)
  const [skipPageReset, setSkipPageReset] = useState(false)

  const { filter } = useParams();

  const expireFilters = [
    'Toate',
    'Expirate',
    'Ne-expirate',
    'Expira in 0-7 zile',
    'Expira in 7-14 zile',
    'Expira in 14-30 zile',
    'Expira in 30+ zile'
  ]

  const activeFilters = [
    'Toate',
    'Activ',
    'Inactiv'
  ]

  const [searchFilter, setSearchFilter] = useState('');
  const [expireFilter, setExpireFilter] = useState('Toate');
  const [activeFilter, setActiveFilter] = useState('Toate');

  const [filteredResellers, setFilteredResellers] = useState(resellers);


  const addResellerUser = async newUserData => {
    try {
      const data = await api.addReseller(newUserData)
      console.log('Add result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        addReseller(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }

  const updateResellerData = useCallback(async (userId, { email, name, suspended, expdate, pwd, pwdconfirm }) => {
    console.log('Update usr', { userId, email, name, suspended, expdate, pwd, pwdconfirm })
    try {
      const data = await api.updateResellerData(userId, { email, name, suspended, expdate, pwd, pwdconfirm })
      console.log('Update result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        updateReseller(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }, [updateReseller])

  const deleteReseller = useCallback(async (userId) => {
    try {
      const data = await api.deleteReseller(userId)
      console.log('Update result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        removeReseller(userId)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }, [removeReseller])

  const renderUserEditForm = useCallback(({ original: user }, toggleAllRowsExpanded) =>
    <div className="p-4">
      <Form title='Editare date reseller' data={{ ...user, expdate: user.expdate.substr(0, 10) }} fields={updateResellerDataFormFields}
        save={data => updateResellerData(user._id, data)}
        dismiss={() => toggleAllRowsExpanded(false)}
        dismissOnSave={false} />
      {/* <Form title='Schimbare parola' data={user} fields={updateUserPwdFormFields} save={updateUserPwd} /> */}
    </div>, [updateResellerData])

  useEffect(() => {
    setSkipPageReset(false)
  }, [resellers])

  useEffect(() => {
    switch (filter) {
      case 'expired':
        setExpireFilter('Expirate');
        setActiveFilter('Toate');
        setSearchFilter('');
        break;
      case 'close-to-expiration':
        setExpireFilter('Expira in 0-7 zile');
        setActiveFilter('Toate');
        setSearchFilter('');
        break;
      case 'suspended':
        setActiveFilter('Inactiv');
        setExpireFilter('Toate');
        setSearchFilter('');
        break;
      case 'active':
        setActiveFilter('Activ');
        setExpireFilter('Ne-expirate');
        setSearchFilter('');
        break;
      default:
        setActiveFilter('Toate');
        setExpireFilter('Toate');
        setSearchFilter('');
    }
  }, [filter])

  useEffect(() => {
    const updatedResellers = applyFilters(resellers, searchFilter, activeFilter, expireFilter);
    setFilteredResellers(updatedResellers);
  }, [expireFilter, activeFilter, searchFilter, resellers])

  return (
    <div className="mt-2">
      <div className="mb-2 d-flex align-items-center justify-content-start">
        <div className="fs-2">Reselleri </div>
        <span className="badge bg-dark px-2 py-1 fs-3 ms-3">{filteredResellers.length} {filteredResellers.length !== resellers.length ? ` (din ${resellers.length})` : ''}</span>
        <button className="btn btn-outline-primary ms-3" onClick={() => setNewUserFormVisible(prev => !prev)}><Icons.PlusCircleFill /> Adauga</button>
      </div>
      {newUserFormVisible && <Form
        title='Reseller nou'
        data={{ name: '', email: '', pwd: '', pwdconfirm: '', expdate: moment().add(1, 'y').format('YYYY-MM-DD') }}
        fields={addResellerFormFields}
        dismiss={() => setNewUserFormVisible(false)}
        save={addResellerUser}
      />}
      <div className='batman-filter-panel'>

        {
          // search filter
        }
        <FloatingLabel
          controlId="floatingInput"
          label="Cautare"
          className="w-100"
        >
          <BForm.Control
            type="text"
            placeholder="Introdu textul de cautare"
            className='batman-filter-search'
            variant='outline-secondary'
            onChange={(e) => setSearchFilter(e.target.value)}
            value={searchFilter}
          />
        </FloatingLabel>

        {
          // Expiration filter
        }
        <FloatingLabel
          controlId="floatingSelect"
          label="Expira"
        >
          <BForm.Select
            value={`${expireFilter} (${applyFilters(resellers, searchFilter, activeFilter, expireFilter).length})`}
            onChange={(e) => setExpireFilter(e.target.value.split('(')[0].trim())}
            className='batman-filter-text'
          >
            {
              expireFilters.map((f, idx) =>
                <option key={idx} >
                  {f} ({applyFilters(resellers, searchFilter, activeFilter, f).length})
                </option>
              )
            }
          </BForm.Select>
        </FloatingLabel>

        {
          // Active filter
        }
        <FloatingLabel
          controlId="floatingSelect"
          label="Activ"
        >
          <BForm.Select
            value={`${activeFilter} (${applyFilters(resellers, searchFilter, activeFilter, expireFilter).length})`}
            onChange={(e) => setActiveFilter(e.target.value.split('(')[0].trim())}
            className='batman-filter-text'
          >
            {
              activeFilters.map((f, idx) =>
                <option key={idx} >
                  {f} ({applyFilters(resellers, searchFilter, f, expireFilter).length})
                </option>
              )
            }
          </BForm.Select>
        </FloatingLabel>

        {
          // Clear filters
        }
        <div
          className='btn btn-info batman-filter-button'
          onClick={() => {
            setActiveFilter('Toate');
            setExpireFilter('Toate');
            setSearchFilter('');
          }}
        >
          Anuleaza filtrele
        </div>
      </div>
      <Table
        data={filteredResellers}
        columns={columns}
        skipPageReset={skipPageReset}
        showGlobalFilter={false}
        onRowClick={(row, toggleAllRowsExpanded) => {
          console.log('Row click', row)
          if (!row.isExpanded) {
            toggleAllRowsExpanded(false)
          }
          row.toggleRowExpanded()
        }}
        actions={{
          setSuspended: ({ _id, suspended }) => updateResellerData(_id, { suspended }),
          deleteReseller: reseller => {
            if (reseller.companies > 0)
              alert(`'${reseller.name}' nu poate fi sters pt ca are ${reseller.companies} companii aferente\nUn reseller se poate sterge doar daca nu are nici o companie atasata.\nPt a putea fi sters companiile aferente trebuiesc transferate altui reseller\n(din ecranul de companii)`)
            else {
              const goon = window.confirm(`Sigur doriti sa stergeti resellerul ${reseller.name}?`)
              if (goon) {
                deleteReseller(reseller._id)
              }
            }
          },
        }}
        renderRowExpansion={renderUserEditForm}
      />
    </div>
  )
}

export default Resellers
