import { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/ro'

const Form = ({ data, fields, title = 'Utilizator nou', submitButtonTitle = 'Salveaza', dismiss, save, dismissOnSave = true }) => {

  const [formData, setFormData] = useState(data)
  const [errors, setErrors] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setFormData(prev => _.isEqual(data, prev) ? prev : data)
  }, [data])

  const set = (name, value) => setFormData(prev => ({ ...prev, [name]: value }))

  const dateChange = (name, value) => {
    const properDateRex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/
    if (properDateRex.test(value)) {
      setFormErrors(prev => ({ ...prev, [name]: undefined }))
      set(name, moment(value, 'DD-MM-YYYY').format('DD-MM-YYYY'))
    } else {
      setFormErrors(prev => ({ ...prev, [name]: ['Data este invalida. Datele trebuie sa fie in formatul DD-MM-YYYY'] }))
      set(name, value)
    }
  }

  return (
    <>
      <div className="form py-3 px-3 bg-light mt-2">

        <div className="d-flex justify-content-between align-items-start position-relative">
          {title ? <h3>{title}</h3> : <div></div>}
          {dismiss ? <button className="btn btn-sm btn-close" onClick={dismiss}></button> : <div></div>}
        </div>
        {errors && errors.length ? errors.map(e => (
          <div className="text-danger">{e}</div>
        )) : null}
        {
          fields.map(([name, type, label, options], index) => {
            // console.log({ name, type, label, options, data: formData[name] })
            switch (type) {
              case 'subtitle': return <h3 key={`form-field-sub-${index}`} >{label}</h3>
              case 'switch': return (
                <div key={`form-field-${name}`} className="form-floating batman-switch mb-3 form-control form-check form-switch">
                  <input className="form-check-input" type="checkbox" id={name} checked={formData[name]} onChange={e => set(name, e.target.checked)} />
                  <label className="floatingInput batman-switch-label" htmlFor={name}>{label}</label>
                </div>
              )
              case 'date':
                return (
                  <div key={`form-field-${name}`} className="form-floating mb-3">
                    <input type='text' className={`form-control ${errors[name] && 'is-invalid'}`} id={name} placeholder={label}
                      value={formData[name] || ''} onChange={e => dateChange(name, e.target.value)} />
                    <label htmlFor="floatingInput">{label}</label>
                    {errors[name]?.map((e, i) => <div key={`${name}-err-${i}`} className="text-danger">{e}</div>)}
                    {formErrors[name]?.map((e, i) => <div key={`${name}-err-${i}`} className="text-danger">{e}</div>)}
                  </div>
                )
              case 'static':
                return (
                  <div key={`form-field-${name}`} className="mb-3">
                    <label>{label}</label>
                    <p>{formData[name]}</p>
                  </div>
                )
              case 'select':
                return (
                  <div key={`form-field-${name}`} className="form-floating mb-3">
                    <select id={`form-field-${name}`} className="form-select" value={formData[name]} onChange={e => set(name, e.target.value)}>
                      {options.map(o => (<option key={`form-field-${name}-${o.value || o}`} value={o.value || o}>{o.label || o}</option>))}
                    </select>
                    <label htmlFor={`form-field-${name}`}>{label}</label>
                  </div>
                )
              default: return (
                <div key={`form-field-${name}`} className="form-floating mb-3">
                  <input type={type} className={`form-control ${errors[name] && 'is-invalid'}`} id={name} placeholder={label}
                    value={formData[name] || ''} onChange={e => set(name, e.target.value)} />
                  <label htmlFor="floatingInput">{label}</label>
                  {errors[name]?.map((e, i) => <div key={`${name}-err-${i}`} className="text-danger">{e}</div>)}
                  {formErrors[name]?.map((e, i) => <div key={`${name}-err-${i}`} className="text-danger">{e}</div>)}
                </div>
              )
            }
          })}

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-warning"
            disabled={saving || Object.keys(formErrors).length && !Object.keys(formErrors).some(e => !formErrors[e])}
            onClick={async () => {
              setSaving(true)
              setTimeout(async () => {
                setErrors({})
                const saveErrors = await save(formData)
                console.log('saveErrors', saveErrors)
                setSaving(false)
                if (saveErrors) {
                  setErrors(saveErrors)
                } else {
                  dismissOnSave && dismiss?.()
                }
              }, 500)
            }}>
            {saving &&
              <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></div>
            }
            {submitButtonTitle}
          </button>
          {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        </div>
      </div>
    </>
  )
}

export default Form
