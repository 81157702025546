import React, { createRef, useContext, useLayoutEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import * as api from './api'

import logo from './img/logo-white-h200.png'
import { LoginContext } from './Contexts'

const LoginForm = props => {

  const { loggedInUser, setLoggedInUser } = useContext(LoginContext)

  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [error, setError] = useState('')


  const emailRef = createRef()
  useLayoutEffect(() => {
    if (emailRef.current) {
      emailRef.current.textContent = "info" + String.fromCharCode(64) + "program-legislatie.ro"
      emailRef.current.setAttribute("href", "mailto:info" + String.fromCharCode(64) + "program-legislatie.ro")
    }
  }, [emailRef])

  const login = async () => {
    try {
      const user = await api.authenticate(email, pass)
      console.log('User after login', user)
      setLoggedInUser(user)
    } catch (err) {
      setError(err)
      console.error(err)
    }
  }

  const { from } = props.location.state || { from: { pathname: '/' } }
  // console.error('Error', error)
  if (loggedInUser) {
    console.log('Login form redirect to', from)
    return <Redirect to={from} />
  }
  return (
    <div id="login-screen" className="h-100 bg-dark">
      {/* <pre>{JSON.stringify({ from })}</pre> */}
      <div className="container h-100">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div>
            <div className="d-flex flex-row justify-content-center align-items-center white mb-4 pb-2">
              <img src={logo} height="100" width="auto" style={{ height: 100, width: "auto" }} alt="logo" />
            </div>
            <div className="card card-body mt-4" style={{ minWidth: 300, maxWidth: 350 }}>
              <div className="form">
                <div className="row">
                  <div className="col-12">
                    <div className="title fs-4">Autentificare</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" placeholder="Email"
                        value={email} onChange={e => setEmail(e.target.value)} autoFocus />
                      <label htmlFor="floatingInput">E-Mail</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type='password' className="form-control" placeholder="Parola"
                        value={pass} onChange={e => setPass(e.target.value)} onKeyUp={e => {
                          if (e.code === 'Enter')
                            login()
                        }} required />
                      <label htmlFor="floatingInput">Parola</label>
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="row">
                    <div className="col-12 pt-2 error">
                      <div className="alert alert-danger alert-dismissible login-error" role="alert">
                        {/\n/.test(error.message) ? (error.message.split('\n').map((l, i) => <div key={`error-${i}`}>{l}</div>)) : error.message}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row pt-3">
                  <div className="col-12">
                    <div className="btn btn-primary fs5 btn-block py-3 w-100" onClick={() => login()}>
                      INTRA IN CONT
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-light thin fs-13 text-center w-100" style={{ position: "absolute", bottom: 5, left: 0 }}>
            <div>
              Linia de asistenta de luni pana vineri intre orele  8:00-18:00:
            </div>
            <div>
              0256-487402 | 0788-123492 | 0788-123496
            </div>
            <div>
              Departament  marketing - vanzari:
            </div>
            <div>
              0722-264869 | 0788-123496
            </div>
            <div>
              email: <a ref={emailRef} href="#email" className="white"> </a>
            </div>
          </div>
        </div>
      </div>
      {/* <RegisterModal onRegister={this.register} registering={this.state.registering} error={this.state.registrationError} /> */}
    </div>

  )
}
export default LoginForm
