import apisauce from 'apisauce'

const api = apisauce.create({
  baseURL: window._env_.REACT_APP_SERVER_URL,
  withCredentials: true,
})

const refreshApi = apisauce.create({
  baseURL: window._env_.REACT_APP_SERVER_URL,
  withCredentials: true,
});

let authenticated = false

let token = localStorage.getItem('token');

api.axiosInstance.interceptors.request.use((config) => {
  if (token) {
    config.headers = {
      token,
    };
  }
  return config;
});

api.axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    console.error(error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // try refreshing the token
      if (token) {
        const { ok, status, headers, originalError } = await refreshApi.get('/api/refresh', null, {
          headers: {
            token,
          },
        })
        if (ok && headers.token) {
          token = headers.token
          localStorage.setItem('token', token)
          originalRequest._retry = true;
          originalRequest.headers.token = token
          return api.axiosInstance(originalRequest)
        } else {
          console.error('Refresh failed', status, originalError)
        }
      }
    }
    throw error
  });

export const isAuthenticated = () => authenticated

export const checkAuth = async () => {
  const { data, ok, problem } = await api.post('/api/admin/test', {})
  if (ok) {
    authenticated = true
    return data
  }
  throw new Error(data ? data.error : problem)
}

export const authenticate = async (email, password) => {
  const { ok, data, problem, headers } = await api.post('/api/login', { email, password })
  if (ok) {
    token = headers.token
    if (token) localStorage.setItem('token', token)
    authenticated = true
    return data
  }
  throw new Error(data ? data.error : problem)
}

export const logout = async () => {
  const { ok, data, problem } = await api.get('/api/logout', {})
  localStorage.removeItem('token')
  if (ok) {
    authenticated = false
    return true
  }
  throw new Error(data ? data.error : problem)
}

export const getCompanies = async () => {
  const { ok, data, status, problem, originalError } = await api.get('/api/admin/companies')

  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getResellers = async () => {
  const { ok, data, status, problem, originalError } = await api.get('/api/admin/resellers')

  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getUsers = async () => {
  const { ok, data, status, problem, originalError } = await api.get('/api/admin/users')

  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getCompany = async id => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/company/${id}`)

  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteCompany = async id => {
  const { ok, data, status, problem, originalError } = await api.delete(`/api/admin/company/${id}`)

  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const transferCompany = async (companyId, newResellerId) => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/company/${companyId}/transfer/${newResellerId}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const addCompany = async ({ name, description, expdate, reseller, canLogin, canUpdate }) => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/company`, { name, description, expdate, reseller, canLogin, canUpdate })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const updateCompanyData = async (companyId, { name, description, expdate, canLogin, canUpdate }) => {
  const { ok, data, status, problem, originalError } = await api.patch(`/api/admin/company/${companyId}`, { name, description, expdate, canLogin, canUpdate })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const addCompanyUser = async (companyId, userData) => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/company/${companyId}/user`, userData)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const updateCompanyUserData = async (companyId, userId, { name, email, suspended, pwd, pwdconfirm }) => {
  const { ok, data, status, problem, originalError } = await api.patch(`/api/admin/company/${companyId}/user/${userId}`,
    { name, email, suspended, pwd, pwdconfirm })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const incLicenses = async (companyId) => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/company/${companyId}/licenses/inc`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const decLicenses = async (companyId) => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/company/${companyId}/licenses/dec`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteLicense = async (companyId, licenseId) => {
  const { ok, data, status, problem, originalError } = await api.delete(`/api/admin/company/${companyId}/license/${licenseId}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const setLicenseActive = async (companyId, licenseId, active) => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/company/${companyId}/license/${licenseId}/${active ? 'activate' : 'deactivate'}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const addReseller = async userData => {
  const { ok, data, status, problem, originalError } = await api.post('/api/admin/reseller', userData)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteReseller = async resellerId => {
  const { ok, data, status, problem, originalError } = await api.delete(`/api/admin/reseller/${resellerId}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const updateResellerData = async (userId, { name, email, suspended, expdate, pwd, pwdconfirm }) => {
  const { ok, data, status, problem, originalError } = await api.patch(`/api/admin/reseller/${userId}`,
    { name, email, suspended, expdate, pwd, pwdconfirm })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const uploadArj = async formData => {
  const { ok, data, status, problem, originalError } = await api.post('/api/admin/imports', formData)
  if (ok) return data
  if (status === 400)
    return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const importArj = async arjfile => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/imports/process/${arjfile}`)
  if (ok) return data
  if (status === 400)
    return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteArj = async arjfile => {
  const { ok, data, status, problem, originalError } = await api.delete(`/api/admin/imports/${arjfile}`)
  if (ok) return data
  if (status === 400)
    return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const undoImportArj = async arjfile => {
  const { ok, data, status, problem, originalError } = await api.post(`/api/admin/imports/undo/${arjfile}`)
  if (ok) return data
  if (status === 400)
    return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getImports = async () => {
  const { ok, data, status, problem, originalError } = await api.get('/api/admin/imports')
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getImportLogs = async arjfile => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/imports/logs/${arjfile}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getConcats = async () => {
  const { ok, data, status, problem, originalError } = await api.get('/api/admin/concats')
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getConcatMeta = async id => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/concats/meta/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getConcatHtml = async id => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/concats/html/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const updateConcatHtml = async (id, html) => {
  const { ok, data, status, problem, originalError } = await api.put(`/api/admin/concats/html/${id}`, html, { headers: { 'content-type': 'text/html' } })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const createAutoConcat = async ids => {
  const { ok, data, status, problem, originalError } = await api.post('/api/admin/concats', { ids })
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteConcat = async file => {
  const { ok, data, status, problem, originalError } = await api.delete(`/api/admin/concats/${file}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const getActEditData = async id => {
  const { ok, data, status, problem, originalError } = await api.get(`/api/admin/edit/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const saveActEditData = async (id, actData) => {
  const { ok, data, status, problem, originalError } = await api.put(`/api/admin/edit/${id}`, actData)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}

export const deleteRev = async id => {
  const { ok, data, status, problem, originalError } = await api.put(`/api/admin/rev/delete/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}
export const restoreRev = async id => {
  const { ok, data, status, problem, originalError } = await api.put(`/api/admin/rev/restore/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}
export const fixRevs = async id => {
  const { ok, data, status, problem, originalError } = await api.put(`/api/admin/rev/fix/${id}`)
  if (ok) return data

  console.error('Something went wrong', { data, status, problem, originalError })
  throw originalError
}
