import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext, LoginContext } from './Contexts'
import './assets/scss/index.scss'


const Home = () => {
  const { loggedInUser } = useContext(LoginContext)
  const { loading, companies, resellers } = useContext(DataContext)

  if (loading) return <div className="d-flex h-100 w-100 justify-content-center align-items-center">Se incarca</div>
  return (
    <div className="row mt-4">
      <div className="batman-home-panel col-12 rounded-3 bg-light p-3 mb-2 position-relative">
        <Link className='batman-home-title' to='/clients'>
          Clienti <span className="badge bg-dark">{companies.length}</span>
        </Link>
        <Link className='batman-home-paragraph' to='/clients/close-to-expiration'>
          Pe cale de expirare: <span className="badge bg-warning">{companies.filter(u => u.days >= 0 && u.days <= 7).length}</span>
        </Link>
        <Link className='batman-home-paragraph' to='/clients/expired'>
          Expirati: <span className="badge bg-danger">{companies.filter(u => u.days < 0).length}</span>
        </Link>
      </div>
      {
        loggedInUser.level === 0 && <div className="batman-home-panel col-12 rounded-3 bg-light p-3 mb-2 position-relative">
          <Link className='batman-home-title' to='/resellers'>
            Reselleri <span className="badge bg-dark">{resellers.length}</span>
          </Link>
          <Link className='batman-home-paragraph' to='/resellers/active'>
            Activi: <span className="badge bg-success">{resellers.filter(u => !u.suspended && u.days > 0).length}</span>
          </Link>
          <Link className='batman-home-paragraph' to='/resellers/close-to-expiration'>
            Pe cale de expirare: <span className="badge bg-warning">{resellers.filter(u => !u.suspended && u.days > 0 && u.days < 7).length}</span>
          </Link>
          <Link className='batman-home-paragraph' to='/resellers/expired'>
            Expirati: <span className="badge bg-danger">{resellers.filter(u => u.days <= 0).length}</span>
          </Link>
          <Link className='batman-home-paragraph' to='/resellers/suspended'>
            Suspendati: <span className="badge bg-danger">{resellers.filter(u => u.suspended).length}</span>
          </Link>
        </div>
      }
      {/* <div className="col-12 rounded-3 bg-light p-3 mb-2 position-relative">
        <Link className="stretched-link" to="/utilizatori"></Link>
        <h3>Utilizatori <span className="badge bg-dark">{users.length}</span></h3>
        <p>
          Activi: <span className="badge bg-success">{users.filter(u => !u.suspended && u.days > 0).length}</span>
        </p>
        <p>
          Pe cale de expirare: <span className="badge bg-warning">{users.filter(u => !u.suspended && u.days > 0 && u.days < 7).length}</span>
        </p>
        <p>
          Suspendati sau expirati: <span className="badge bg-danger">{users.filter(u => u.suspended || u.days <= 0).length}</span>
        </p>
      </div> */}
    </div >
  )
}

export default Home
