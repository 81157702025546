
import { useCallback, useContext, useMemo, useState } from 'react'
import { Table } from './Table'
import * as Icons from 'react-bootstrap-icons'
import * as api from './api'
import { DataContext } from './Contexts'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ArjImportActionsCell = ({ importArj, deleteArj, arjfile, importableAfter }) => {

  const [working, setWorking] = useState(false)

  return (
    <div className="d-flex flex-column align-items-center">
      {
        !importableAfter
          ? (
            <button className="mt-1 px-4 btn btn-sm btn-primary" onClick={async () => {
              setWorking(true)
              if (!await importArj(arjfile))
                setWorking(false)
            }}>
              <Icons.CloudUploadFill /> Import {working ? <Spinner animation="border" size="sm" /> : null}
            </button>
          )
          : (
            <div className="small" style={{ wordBreak: 'break-word' }} disabled>
              Se poate importa doar dupa '{importableAfter}'
            </div>
          )
      }
      <button className="mt-1 btn px-4 btn-sm btn-danger" onClick={async () => {
        if (window.confirm(`Sigur stergem ${arjfile}?`)) {
          setWorking(true)
          if (!await deleteArj(arjfile))
            setWorking(false)
        }
      }}>
        <Icons.DashCircleFill /> Sterge {working ? <Spinner animation="border" size="sm" /> : null}
      </button>
    </div>
  )

}

const UndoArjImportCell = ({ undoImportArj, arjfile, undoableAfter }) => {

  const [working, setWorking] = useState(false)

  return (
    <div className="d-flex flex-column align-items-center">
      {
        !undoableAfter
          ? (
            <button className="mt-1 px-4 btn btn-sm btn-warning" onClick={async () => {
              if (window.confirm(`Sigur revocam importul ${arjfile}?`)) {
                setWorking(true)
                if (!await undoImportArj(arjfile))
                  setWorking(false)
              }
            }}>
              <Icons.ArrowCounterclockwise /> Revoca {working ? <Spinner animation="border" size="sm" /> : null}
            </button>
          )
          : (
            <div className="small" style={{ wordBreak: 'break-word' }} disabled>
              Se poate revoca doar dupa '{undoableAfter}'
            </div>
          )
      }
    </div>
  )
}

const ImportPage = () => {

  const [uploadVisible, setUploadVisible] = useState(false)
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  const { uploaded, imported, loading, reloadImports } = useContext(DataContext)

  const uploadARJ = useCallback(async () => {
    if (!files.length) return
    const formData = new FormData()
    formData.append('arj', files[0])
    setUploading(true)
    const { error } = await api.uploadArj(formData)
    setUploading(false)
    if (error) {
      alert(error)
    } else {
      setUploadVisible(false)
      await reloadImports()
    }
  }, [files, reloadImports])

  const importArj = useCallback(async arjfile => {
    console.log(arjfile)
    // await new Promise(r => setTimeout(r,3000))
    try {
      const { ok, errors } = await api.importArj(arjfile)
      if (ok) {
        await reloadImports()
        return true
      } else if (errors) {
        alert('Erori:\n' + errors.join('\n'))
      } else {
        alert('Eroare necunoscuta')
      }
    } catch (error) {
      alert(error)
    }
  }, [reloadImports])

  const deleteArj = useCallback(async arjfile => {
    console.log(arjfile)
    // await new Promise(r => setTimeout(r,3000))
    try {
      const { ok, errors } = await api.deleteArj(arjfile)
      if (ok) {
        await reloadImports()
        return true
      } else if (errors) {
        alert('Erori:\n' + errors.join('\n'))
      } else {
        alert('Eroare necunoscuta')
      }
    } catch (error) {
      alert(error)
    }
  }, [reloadImports])

  const undoImportArj = useCallback(async arjfile => {
    console.log(arjfile)
    // await new Promise(r => setTimeout(r,3000))
    try {
      const { ok, errors } = await api.undoImportArj(arjfile)
      if (ok) {
        await reloadImports()
        return true
      } else if (errors) {
        alert('Erori:\n' + errors.join('\n'))
      } else {
        alert('Eroare necunoscuta')
      }
    } catch (error) {
      alert(error)
    }
  }, [reloadImports])

  const uploadedColumns = useMemo(() => [
    {
      Header: 'Cand/ARJ',
      Cell: ({ row: { original: { whentxt, arjfile, size } } }) => <><div>{whentxt}</div><div>{arjfile} [{size}]</div></>
    },
    {
      Header: 'Status',
      Cell: () => <span className="badge bg-secondary">Pregatit</span>
    },
    {
      Header: 'Actiuni',
      Cell: ({ row: { original: { importableAfter, arjfile } } }) => (
        <ArjImportActionsCell {...{ importableAfter, arjfile, importArj, deleteArj }} />
      )
    },
  ], [importArj, deleteArj])

  const importedColumns = useMemo(() => [
    {
      Header: 'Cand/ARJ',
      Cell: ({ row: { original: { whentxt, arjfile, size } } }) => <><div>{whentxt}</div><div>{arjfile} [{size}]</div></>
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: () => <span className="badge bg-success">Importat</span>
    },
    {
      Header: 'Actiuni',
      Cell: ({ row: { original: { undoableAfter, arjfile } } }) => (
        <UndoArjImportCell {... { undoableAfter, arjfile, undoImportArj }} />
      )
    },
    {
      Header: 'Logs',
      Cell: ({ row: { original: { arjfile } } }) => <Link className="mt-1 btn btn-sm btn-secondary align-self-end" to={`importlogs/${arjfile}`}>Raport</Link>
    }
  ], [undoImportArj])

  return (
    <div className="mt-2">
      <div className="d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-center">
          <h2>Importuri</h2>
          <div className="btn btn-outline-dark btn-sm ms-4" onClick={reloadImports}>
            <Icons.ArrowRepeat className="fs-4" />
            {loading &&
              <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></div>
            }
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-end my-4">
          <button className="btn btn-sm btn-success" onClick={() => {
            if (uploadVisible) setFiles([])
            setUploadVisible(prev => !prev)
          }}>
            <Icons.PlusCircleFill /> Adauga fisier nou
          </button>

          {uploadVisible && <div id="upload" className="form mt-2">
            <div className="d-flex flex-column">
              <div className="form-group">
                <label className="custom-file">
                  <input type="file" id="arjfile" multiple={false} className="custom-file-input" onChange={e => setFiles(e.target.files)} />
                  <span className="custom-file-control"></span>
                  <span>{files.length ? files[0].name : null}</span>
                </label>
              </div>
              <button className="btn" id="upload_btn" disabled={!files.length} onClick={uploadARJ}>
                <Icons.Upload /> Upload
                {uploading &&
                  <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></div>
                }
              </button>
            </div>
          </div>}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Table
            columns={uploadedColumns}
            data={uploaded}
          />
        </div>
        <div className="col-6">
          <Table
            columns={importedColumns}
            data={imported}
          />
        </div>
      </div>
    </div>
  )
}

export default ImportPage
