import * as api from './api'
import { useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { DataContext } from './Contexts'
import Form from './Form'
import { Table } from './Table'

const updateUserDataFormFields = [
  ['name', 'text', 'Nume'],
  ['email', 'email', 'Email'],
  ['', 'subtitle', 'Schimbare parola (optional)'],
  ['pwd', 'password', 'Parola'],
  ['pwdconfirm', 'password', 'Confirmarea parolei'],
]

const CompanyUsersTable = ({ company }) => {

  const { updateCompany } = useContext(DataContext)
  const [skipPageReset, setSkipPageReset] = useState(false)

  const updateUserData = useCallback(async (userId, { email, name, suspended, pwd, pwdconfirm }) => {
    console.log('Update usr', { userId, email, name, suspended, pwd, pwdconfirm })
    try {
      const data = await api.updateCompanyUserData(company._id, userId, { email, name, suspended, pwd, pwdconfirm })
      console.log('Update result', data)
      if (data.errors) {
        return data.errorsByField
      } else {
        updateCompany(data)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
      return ['Eroare la salvare', error.message]
    }
  }, [company._id, updateCompany])

  useEffect(() => {
    setSkipPageReset(false)
  }, [company.users])

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'nr',
      disableGroupBy: true,
      Cell: ({ row: { index, original: { name, email, suspended, days } } }) => {
        return (
          <div className={`d-flex flex-column align-items-start w-100 flex-grow-1 ${suspended && 'text-danger'} ${days < 0 && 'text-decoration-line-through text-muted'}`}>
            <strong>{index + 1}</strong>
          </div>
        )
      }
    },
    {
      Header: 'Nume',
      accessor: 'name',
      disableGroupBy: true,
      Cell: ({ row: { original: { name, email, suspended, days } } }) => {
        return (
          <div className={`d-flex flex-column align-items-start w-100 flex-grow-1 ${suspended && 'text-danger'} ${days < 0 && 'text-decoration-line-through text-muted'}`}>
            <strong>{name}</strong>
          </div>
        )
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableGroupBy: true,
      Cell: ({ row: { original: { name, email, suspended, days } } }) => {
        return (
          <div className={`d-flex flex-column align-items-start w-100 flex-grow-1 ${suspended && 'text-danger'} ${days < 0 && 'text-decoration-line-through text-muted'}`}>
            <div>{email}</div>
          </div>
        )
      }
    },
    {
      Header: 'Sesiuni Active',
      accessor: ({ activeTokens }) => Number(activeTokens?.[0] || 0),
      sortDescFirst: true,
      isNumber: true,
    },
    {
      Header: 'Activ',
      accessor: ({ suspended }) => suspended ? 'NU' : 'DA',
      disableGroupBy: true,
      Cell: (instance) => {
        // console.log(instance)
        const {
          value,
          row: { original: { _id, suspended } },
          actions,
        } = instance
        // console.log(instance.data[0])
        return <div className="form-check form-switch fs-5">
          {value}
          <input className="form-check-input" type="checkbox"
            onClick={e => {
              // e.preventDefault()
              e.stopPropagation()
            }}
            checked={!suspended}
            onChange={e => {
              console.log('click', _id, e.target.checked)
              actions.setSuspended({ _id, suspended: !e.target.checked })
            }}
          />
        </div>
      }
    }
  ], [])

  const renderUserEditForm = useCallback(({ original: user }, toggleAllRowsExpanded) =>
    <div className="p-4">
      <Form title='Editare date utilizator' data={{ ...user, expdate: user.expdate.substr(0, 10) }} fields={updateUserDataFormFields}
        save={data => updateUserData(user._id, data)}
        dismiss={() => toggleAllRowsExpanded(false)}
        dismissOnSave={false} />
      {/* <Form title='Schimbare parola' data={user} fields={updateUserPwdFormFields} save={updateUserPwd} /> */}
    </div>, [updateUserData])

  return <Table
    columns={columns}
    data={company.users}
    skipPageReset={skipPageReset}
    onRowClick={(row, toggleAllRowsExpanded) => {
      console.log('Row click', row)
      if (!row.isExpanded) {
        toggleAllRowsExpanded(false)
      }
      row.toggleRowExpanded()
    }}
    actions={{
      setSuspended: ({ _id, suspended }) => updateUserData(_id, { suspended })
    }}
    renderRowExpansion={renderUserEditForm}
  />

}

export default CompanyUsersTable
