import React, { useContext, useEffect, useState, useMemo } from 'react'

import { Button, Accordion, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router'
import * as api from './api'
import { DataContext } from './Contexts'

const ImportLogsPage = (props) => {

  const { match: { params: { importId } } } = props
  const history = useHistory()
  const { imported } = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const [markdown, setMarkdown] = useState('')

  const [search, setSearch] = useState('')

  const filtered = useMemo(() => {
    if (!search) return imported
    return imported.filter(i => i.arjfile.includes(search))
  }, [search, imported])

  useEffect(() => {
    const fetchLog = async () => {
      if (importId) {
        try {
          setLoading(true)
          const data = await api.getImportLogs(importId)
          setMarkdown(data)
        } catch (error) {
          alert(error)
        } finally {
          setLoading(false)
        }
      }
    }
    fetchLog()
  }, [importId])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-4">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{importId || 'Alegeti fisierul ARJ importat'}</Accordion.Header>
              <Accordion.Body>
                <div>
                  <input type="text" className="form-control" placeholder={'cautare'}
                    value={search} onChange={e => setSearch(e.target.value)} />
                </div>
                <div className="d-flex flex-wrap flex-row align-items-center justify-content-start mt-2">
                  {filtered.map(({ arjfile }) => (
                    <Button className={`mx-1`}
                      variant="dark"
                      key={arjfile}
                      onClick={() => history.push(`/importlogs/${arjfile}`)}
                      disabled={arjfile === importId}>
                      {arjfile}
                    </Button>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-12 mt-4">
          {
            loading
              ? <Spinner animation="border" />
              : <pre>{markdown}</pre>
          }
        </div>
      </div>
    </div >
  )
}

export default ImportLogsPage
