import React, { useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import "react-datetime/css/react-datetime.css"
import './App.css'
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom'
import { LoginContext, LoginContextWrapper, DataContextWrapper } from './Contexts'

import CompaniesPage from './CompaniesPage'
import ResellersPage from './ResellersPage'
import ImportPage from './ImportPage'
import ImportLogsPage from './ImportLogsPage'
import { CompanyPage } from './CompanyPage'
import LoginPage from './LoginPage'
import Logout from './Logout'
import HomePage from './HomePage'
import ConcatsPage from './ConcatsPage'
import ConcatEditPage from './ConcatEditPage'
import EditActPage from './EditActPage'
import MockAdvancedSearch from './MockAdvancedSearch'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedInUser } = useContext(LoginContext)
  // console.log('Private route', { loggedInUser, rest, Component })
  return (
    <Route {...rest} render={props => {
      if (loggedInUser) {
        return (
          <>
            <nav className="navbar navbar-expand-lg navbar-light bg-info">
              <div className="container">
                <Link className="navbar-brand" to="/">Acasa</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {/* <li className="nav-item">
                      <Link className={`nav-link`} to="/mock-advanced-search">Mock Advanced Search</Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className={`nav-link ${window.location.href.includes('clients')}`} to="/clients">Clienti</Link>
                    </li>
                    {
                      loggedInUser.level === 0 && <li className="nav-item">
                        <Link className={`nav-link ${window.location.href.includes('resellers')}`} to="/resellers">Reselleri</Link>
                      </li>
                    }
                    {
                      loggedInUser.level === 0 && <li className="nav-item">
                        <Link className="nav-link" to="/import">Import</Link>
                      </li>
                    }
                    {
                      loggedInUser.level === 0 && <li className="nav-item">
                        <Link className="nav-link" to="/concatenari">Concatenari</Link>
                      </li>
                    }
                  </ul>
                  <div className="nav-item ms-auto">
                    <Link className="btn btn-sm btn-outline-dark" to="/logout">Iesire</Link>
                  </div>
                </div>
              </div>
            </nav>
            <div className="container">

              <Component {...props} />
            </div>
          </>
        )
      }
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
    }} />
  )
}

const App = () => {

  return (
    <BrowserRouter>
      <LoginContextWrapper>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <DataContextWrapper>
            <PrivateRoute exact path="/" component={HomePage} />
            {/* <PrivateRoute exact path="/utilizatori" component={Users} /> */}
            {/* <PrivateRoute exact path="/mock-advanced-search" component={MockAdvancedSearch} /> */}
            <PrivateRoute exact path="/clients/:filter?" component={CompaniesPage} />
            <PrivateRoute path="/client/:id" component={CompanyPage} />
            <PrivateRoute exact path="/resellers/:filter?" component={ResellersPage} />
            <PrivateRoute exact path="/import" component={ImportPage} />
            <PrivateRoute exact path="/importlogs/:importId?" component={ImportLogsPage} />
            <PrivateRoute exact path="/concatenari" component={ConcatsPage} />
            <PrivateRoute exact path="/editconcat/:concatid" component={ConcatEditPage} />
            <PrivateRoute exact path="/editact/:id" component={EditActPage} />
            {/* <PrivateRoute exact path="/editacttext/:id" component={EditActTextPage} /> */}
            <PrivateRoute exact path="/logout" component={Logout} />
          </DataContextWrapper>
        </Switch>
      </LoginContextWrapper>
    </BrowserRouter>
  );
}

export default App;
