import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import * as api from './api'

const delay = ms => new Promise(r => setTimeout(r, ms))

const concatLines = async (lines, onSuccess, onError) => {
  if (!lines.length) {
    await delay(200)
    return
  }
  for (const line of lines) {
    const data = await api.createAutoConcat(line.ids)
    if (data.ok) onSuccess(line.ids.join(','), data.message)
    else onError(line.ids.join(','), data.error)
    await delay(200)
  }
}

const ConcatsPage = () => {

  const [loading, setLoading] = useState(false)
  const [concatenating, setConcatenating] = useState(false)
  const [undoing, setUndoing] = useState(false)
  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const data = await api.getConcats()
      setConcats(data)
    } catch (error) {
      alert(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const [concats, setConcats] = useState([])
  const [idsLines, setIdsLines] = useState('')
  const [concatResults, setConcatResults] = useState([])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const concat = useCallback(async () => {

    const lines = idsLines.split('\n').map(l => l.trim()).filter(Boolean).map(line => ({ line, ids: line.split(/[ ,]+/).map(id => id.trim()) }))
    const liniiCuPreaPutineIds = lines.filter(l => l.ids.length < 2)

    if (!lines.length) {
      return
    }
    if (liniiCuPreaPutineIds.length) {
      alert(`Au fost gasite ${liniiCuPreaPutineIds.length} linii cu un singur id.\nVerificati corectitudinea pentru liniile urmatoare:\n${liniiCuPreaPutineIds.map(l => l.line).join('\n')}`)
      return
    }

    setConcatResults([])
    setConcatenating(true)

    await concatLines(lines,
      (line, message) => {
        setConcatResults(prev => [...prev, { line, message }])
        // $('#result').append(`<pre style="color:green">${line}\n  -> ${message}</pre>`)
      },
      (line, error) => {
        setConcatResults(prev => [...prev, { line, error }])
        // $('#result').append(`<pre style="color:red">${line}\n  -> ${error}</pre>`)
      })
    setConcatenating(false)
    await delay(500)
    await fetchData()
  }, [idsLines, fetchData])

  const deleteConcat = useCallback(async file => {
    setUndoing(true)
    await api.deleteConcat(file)
    await fetchData()
    setUndoing(false)
  }, [fetchData])

  return (
    <div className="mt-2">
      <div className="d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-center">
          <h2>Concatenari</h2>
          <div className="btn btn-outline-dark btn-sm ms-4" onClick={fetchData}>
            <Icons.ArrowRepeat className="fs-4" />
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          <form className="form">
            <div className="form-group">
              <h4>Id-uri documente separate prin spatiu sau virgula,
                in ordinea EXACTA in care trebuie concatenate:</h4>
              <div className="input-group mb-3">
                <textarea
                  className="form-control"
                  rows={10}
                  placeholder="cate un rand cu id-uri documente in ordinea EXACTA a concatenatenarii separate prin virgula/spatiu, ex: id1,id2,id3[ENTER]idX,idY,idZ[ENTER]..."
                  value={idsLines} onChange={e => setIdsLines(e.target.value)}></textarea>
              </div>
            </div>
          </form>
          <div className={`btn btn-dark mb-2 ${concatenating ? 'disabled' : ''}`} onClick={concat}>
            Concateneaza automat {concatenating && <Spinner animation="border" size="sm" />}
          </div>
          <div id="result">
            {concatResults.map(({ line, message, error }, index) => <pre key={`res-${index}`} style={{ color: message ? 'green' : 'red' }}>{line}
              {'->'} {message || error}</pre>)}
          </div>
          <div style={{ border: '1px solid black', padding: '10px' }} className="mt-2">
            <div className="small">
              Dupa de dati click pe acest buton actele vor fi concatenate
              automat cu un marker de delimitare unde documentele au fost lipite (pe care il veti
              putea folosi pt gasirea usoara a zonelor de lipire ce trebuiesc editate)
            </div>
            <div className="small">
              Odata creata aceasta concatenare automata ea va aparea in
              lista de mai jos si o veti putea edita, vizualiza sau sterge (cele inca ne-editate
              apar mai sus in lista)
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner animation="border" />}

      <div className="col-12">
        <h5>Lista concatenari existente:</h5>
        {concats.length === 0 ? "-- Nu exista inca concatenari --" : null}
        {concats.map(({ lexids, date, meta, reviewed, _id, file }, index) => {
          const autoconcat = !reviewed // /CONCATENAT AUTOMAT/.test(doc.status)
          const bgcolor = autoconcat ? "#AAA" : "darkgreen"
          const color = autoconcat ? "#fd7e14" : "darkgreen"
          return (
            <div key={lexids.join(',') + 'group'} className={`d-flex justify-content-start align-items-center mb-3 p-1`}
              style={{ border: `2px dashed ${bgcolor}`, color }}>
              <div>
                <div className="btn btn-outline-dark px-1 disabled align-self-start">
                  {index + 1}. in data de: {date}
                </div>
                {meta.map(({ lexid, newtype, issuer_nr, issue_year, issuer_name, rev_info }, i) => (
                  <div key={'' + i + lexid}>{lexid} - <strong>{newtype} nr. {parseInt(issuer_nr)}/{issue_year}</strong> {issuer_name} {rev_info}</div>
                ))}
              </div>
              <div className="ml-auto d-flex flex-column align-items-end flex-grow-1">
                {/* <a target="_blank" className="m-1 btn btn-sm btn-primary">Vizualizeaza</a> */}
                <Link to={`/editconcat/${_id}`} target="_blank" className="m-1 btn btn-sm btn-warning">Editeaza</Link>
                <div className="btn btn-danger btn-sm delete_btn m-1"
                  onClick={() => window.confirm(`Sigur vreti sa revocati concatenarea:\n${lexids.join(',')}\n?`) && deleteConcat(file)}>
                  Sterge {undoing && <Spinner animation="border" size="sm" />}
                </div>
              </div>
            </div>
          )
        })}
      </div>

    </div>
  )
}

export default ConcatsPage
